@import "../../../styles/variables.scss";

.management-container {
  .banner {
    position: relative;
    background-image: url("../../../assests/management/management.jpg");
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.79);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .banner-content {
      z-index: 2;

      .banner-title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
        font-family: $unbounded;
        text-align: start;
        text-transform: capitalize;
        color: $headeing-color;
      }

      .banner-description {
        font-size: 14px !important;
        font-family: "Montserrat", sans-serif;
        font-weight: 500 !important;
        text-align: start;
        text-transform: capitalize;
        color: $headeing-color;
      }
    }
  }

  .management-title {
    font-family: $text-color;
    font-weight: 600;
    font-size: 2rem;
    text-align: left;
    padding: 1rem;
    text-transform: capitalize;
  }

  .management-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-transform: capitalize;
    text-align: left;
    padding: 1rem;
    text-transform: capitalize;
  }

  .card-desc {
    font-family: "Montserrat", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: capitalize;
    text-align: left;
    margin-top: 1rem;
    text-transform: capitalize;
  }

  .management-data {
    border: 2px solid #000 !important;
    padding: 0 !important;
    border-radius: 25px !important;
    margin: 0.2rem !important;
  }

  .management-card {
    width: 350px !important;
    height: 450px !important;
    border-radius: 25px !important;
    box-shadow: none !important;
    border-radius: 0%;
    border: none !important;
    outline: none !important;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }

  .management-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .management-title {
    font-family: "Unbounded", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    text-transform: capitalize;
  }

  .management-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-transform: capitalize;
  }

  .management-subtitle {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    color: #000000;
  }

  .management-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0 !important;
    padding: 10px !important;
  }

  .management-social-icons {
    display: flex !important;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
  }

  .management-icons {
    font-size: 15px;
    cursor: pointer;
    font-weight: 800;
    transition: color 0.5s, transform 0.5s;

    &:hover {
      transform: scale(1.2);
    }
  }

  .meta {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #000;
    border-radius: 50%;
    color: #6528f7;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .insta {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #000;
    border-radius: 50%;
    color: #a0153e;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .twitter {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #000;
    border-radius: 50%;
    color: #6499e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .linkedin {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #000;
    border-radius: 50%;
    color: #0051ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg1 {
    background-color: #a8dadc85;
  }

  .bg2 {
    background-color: #c2b97f6b;
  }

  .bg3 {
    background-color: #cdcdcd88;
  }

  .bg4 {
    background-color: #52fa9238;
  }

  .management-team-heading {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background-color: $text-color;
      z-index: -1;
    }

    h2 {
      font-family: $unbounded;
      font-size: 4rem;
      font-weight: 600;
      color: $text-color;
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  .management-container {
    .banner {
      .banner-content {
        z-index: 2;

        .banner-title {
          font-size: 2.5rem;
          font-weight: 800;
          margin-bottom: 1rem;
          font-family: $unbounded;
          text-align: start;
          text-transform: capitalize;
          color: $headeing-color;
        }

        .banner-description {
          font-size: 18px !important;
          font-family: "Montserrat", sans-serif;
          font-weight: 600 !important;
          text-align: start;
          text-transform: capitalize;
          color: $headeing-color;
        }
      }
    }

    .management-team-heading {
      h2 {
        font-family: $text-color;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        text-transform: capitalize;
      }
      p {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-transform: capitalize;
        text-align: left;
        text-transform: capitalize;
      }
    }

    .management-data {
      .management-title {
        font-family: "Unbounded", sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        text-transform: capitalize;
        span {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-transform: capitalize;
          color: #000000;
        }
      }

      .management-text {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 15px;
        text-transform: capitalize;
        margin: 0 !important;
        padding: 10px !important;
      }

      .management-social-icons {
        display: flex !important;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;

        .twitter {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid #000;
          border-radius: 50%;
          color: #6499e9;
          display: flex;
          justify-content: center;
          align-items: center;

          .management-icons {
            font-size: 15px;
            cursor: pointer;
            font-weight: 800;
            transition: color 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        .linkedin {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid #000;
          border-radius: 50%;
          color: #0051ff;
          display: flex;
          justify-content: center;
          align-items: center;

          .management-icons {
            font-size: 15px;
            cursor: pointer;
            font-weight: 800;
            transition: color 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .management-card {
      width: 100% !important;
      height: auto !important;
      border-radius: 25px !important;
      box-shadow: none !important;
      border-radius: 0%;
      border: none !important;
      outline: none !important;
      padding: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;

      .management-img {
        width: 100%;
        height: 100%;
        object-fit: contain !important;
      }
    }

    .bg1 {
      background-color: #a8dadc85;
    }

    .bg3 {
      background-color: #cdcdcd88;
    }
  }
}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  .management-container {
    .banner {
      .banner-content {
        z-index: 2;

        .banner-title {
          font-size: 3rem;
          font-weight: 800;
          margin-bottom: 1rem;
          font-family: $unbounded;
          text-align: start;
          text-transform: capitalize;
          color: $headeing-color;
        }

        .banner-description {
          font-size: 18px !important;
          font-family: "Montserrat", sans-serif;
          font-weight: 600 !important;
          text-align: start;
          text-transform: capitalize;
          color: $headeing-color;
        }
      }
    }

    .management-team-heading {
      h2 {
        font-family: $text-color;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        padding: 1rem;
        text-transform: capitalize;
      }
      p {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-transform: capitalize;
        text-align: left;
        padding: 1rem;
        text-transform: capitalize;
      }
    }

    .management-data {
      .management-title {
        font-family: "Unbounded", sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        text-transform: capitalize;
        span {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 12px;
          text-transform: capitalize;
          color: #000000;
        }
      }

      .management-text {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-transform: capitalize;
        margin: 0 !important;
        padding: 10px !important;
      }

      .management-social-icons {
        display: flex !important;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;

        .twitter {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid #000;
          border-radius: 50%;
          color: #6499e9;
          display: flex;
          justify-content: center;
          align-items: center;

          .management-icons {
            font-size: 15px;
            cursor: pointer;
            font-weight: 800;
            transition: color 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        .linkedin {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid #000;
          border-radius: 50%;
          color: #0051ff;
          display: flex;
          justify-content: center;
          align-items: center;

          .management-icons {
            font-size: 15px;
            cursor: pointer;
            font-weight: 800;
            transition: color 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .management-card {
      width: 100% !important;
      height: auto !important;
      border-radius: 25px !important;
      box-shadow: none !important;
      border-radius: 0%;
      border: none !important;
      outline: none !important;
      padding: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;

      .management-img {
        width: 100%;
        height: 100%;
        object-fit: contain !important;
      }
    }

    .bg1 {
      background-color: #a8dadc85;
    }

    .bg3 {
      background-color: #cdcdcd88;
    }
  }
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .management-container {
    .banner {
      .banner-content {
        z-index: 2;

        .banner-title {
          font-size: 3rem;
          font-weight: 800;
          margin-bottom: 1rem;
          font-family: $unbounded;
          text-align: start;
          text-transform: capitalize;
          color: $headeing-color;
        }

        .banner-description {
          font-size: 18px !important;
          font-family: "Montserrat", sans-serif;
          font-weight: 600 !important;
          text-align: start;
          text-transform: capitalize;
          color: $headeing-color;
        }
      }
    }

    .management-team-heading {
      h2 {
        font-family: $text-color;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        padding: 1rem;
        text-transform: capitalize;
      }
      p {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-transform: capitalize;
        text-align: left;
        padding: 1rem;
        text-transform: capitalize;
      }
    }

    .management-data {
      .management-title {
        font-family: "Unbounded", sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        text-transform: capitalize;
        span {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 12px;
          text-transform: capitalize;
          color: #000000;
        }
      }

      .management-text {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-transform: capitalize;
        margin: 0 !important;
        padding: 10px !important;
      }

      .management-social-icons {
        display: flex !important;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;

        .twitter {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid #000;
          border-radius: 50%;
          color: #6499e9;
          display: flex;
          justify-content: center;
          align-items: center;

          .management-icons {
            font-size: 15px;
            cursor: pointer;
            font-weight: 800;
            transition: color 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        .linkedin {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid #000;
          border-radius: 50%;
          color: #0051ff;
          display: flex;
          justify-content: center;
          align-items: center;

          .management-icons {
            font-size: 15px;
            cursor: pointer;
            font-weight: 800;
            transition: color 0.5s, transform 0.5s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .management-card {
      width: 350px !important;
      height: 450px !important;
      border-radius: 25px !important;
      box-shadow: none !important;
      border-radius: 0%;
      border: none !important;
      outline: none !important;
      padding: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;

      .management-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bg1 {
      background-color: #a8dadc85;
    }

    .bg3 {
      background-color: #cdcdcd88;
    }
  }
}
