@import "../../styles/variables.scss";

.video-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;

  .video-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(16, 44, 16, 0.616), rgba(0, 0, 0, 0.89));
    pointer-events: none;
  }

  .caption {
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 10;
    color: $headeing-color;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
    width: 80vw;
    padding-top: 0;

    h3 {
      margin: 0;
      width: 70vw;
      font-size: 1.8rem;
      font-family: $unbounded;
      font-weight: 600;

      .span {
        margin-bottom: 1rem;
        color: $headeing-color;
        font-size: 6rem;
        font-family: $unbounded;
        font-weight: 600;
        width: 500px;
      }
    }

    p {
      margin-top: 3rem;
      line-height: 2.8rem;
      font-weight: 500;
      width: 600px;
      color: $headeing-color;
    }
  }

  .caption-text {
    position: absolute;
    right: 0;
    bottom: 15%;
    z-index: 10;
    width: 35%;
    text-align: left;
    font-family: $unbounded;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 2rem;
    margin-left: 10px;
    padding: 20px;
    color: $headeing-color;

    .text {
      color: $headeing-color;
      margin-top: 10px;
      font-family: $mont;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  .video-container {
    .caption {
      position: absolute;
      top: 10%;
      left: 0%;
      z-index: 10;
      color: $headeing-color;
      text-align: left;
      padding: 20px;
      border-radius: 10px;
      width: 100vw;
      padding-top: 0;

      h3 {
        margin: 0;
        width: 100%;

        .span {
          margin-bottom: 1rem;
          color: $headeing-color;
          font-size: 2.2rem;
          font-family: $unbounded;
          font-weight: 600;
          width: 500px;
        }
      }

      p {
        font-size: 1rem;
        font-family: $unbounded;
        line-height: 1.5rem;
        font-weight: 500;
        width: 80vw;
        color: $headeing-color;
      }
    }

    .caption-text {
      position: absolute;
      right: 0;
      bottom: 20%;
      z-index: 10;
      width: 100%;
      text-align: left;
      font-family: $unbounded;
      font-size: 1rem;
      font-weight: 600;
      text-transform: capitalize;
      margin-left: 10px;
      padding: 20px;
      color: $headeing-color;
      margin-top: 1rem;
    }
  }
}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  .video-container {
    .caption {
      position: absolute;
      top: 10%;
      left: 0%;
      z-index: 10;
      color: $headeing-color;
      text-align: left;
      padding: 20px;
      border-radius: 10px;
      width: 80vw;
      padding-top: 0;

      h3 {
        margin: 0;
        width: 70vw;

        .span {
          margin-bottom: 1rem;
          color: $headeing-color;
          font-size: 4rem;
          font-family: $unbounded;
          font-weight: 600;
          width: 500px;
        }
      }

      p {
        line-height: 1.8rem;
        font-size: 1.2rem;
        font-weight: 700;
        width: 600px;
        color: $headeing-color;
      }
    }

    .caption-text {
      position: absolute;
      right: 0;
      bottom: 20%;
      z-index: 10;
      width: 100%;
      text-align: left;
      font-family: $unbounded;
      font-size: 1rem;
      font-weight: 600;
      text-transform: capitalize;
      margin-left: 10px;
      padding: 20px;
      color: $headeing-color;
    }
  }
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .video-container {
    .caption {
      position: absolute;
      top: 10%;
      left: 0%;
      z-index: 10;
      color: $headeing-color;
      text-align: left;
      padding: 20px;
      border-radius: 10px;
      width: 80vw;
      padding-top: 0;

      h3 {
        margin: 0;
        width: 100% !important;
        font-size: 1rem;
        font-family: $unbounded;
        font-weight: 600;

        .span {
          margin-bottom: 1rem;
          color: $headeing-color;
          font-size: 4rem;
          font-family: $unbounded;
          font-weight: 800;
          width: 500px;
        }
      }

      p {
        font-weight: 500;
        width: 80%;
        line-height: 1.8rem !important;
        color: $headeing-color;
        font-family: $unbounded;
        width: 600px;
      }
    }

    .caption-text {
      position: absolute;
      right: 0;
      bottom: 15%;
      z-index: 10;
      width: 600px;
      text-align: left;
      font-family: $unbounded;
      font-size: 1rem;
      font-weight: 600;
      text-transform: capitalize;
      line-height: 1.8rem;
      margin-left: 10px;
      padding: 20px;
      color: $headeing-color;
    }
  }
}
