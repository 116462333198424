@import "../../../../styles/variables.scss";

.attendence-container {
  .attendence-banner-container {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;

    .attendence-banner {
      position: relative;
      width: 100%;
      height: 600px;
      background-image: url("../../../../assests/Producst/Indentity/Attendence.png");
      background-size: 45%;
      background-position: calc(100% - 200px) 10px;
      background-repeat: no-repeat;

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.668);
        z-index: 1;
      }

      .text-content {
        position: absolute;
        top: 20%;
        left: 5%;
        // transform: translate(-50%, -50%);
        text-align: left;
        z-index: 2;
        width: 600px;

        h1 {
          color: $headeing-color;
          font-size: 2.8rem;
          font-weight: 600;
          margin: 0;
          font-family: $unbounded;
        }

        p {
          font-size: 16px;
          color: $headeing-color;
          font-weight: 500;
          font-family: $mont;
          margin-top: 20px;
        }
      }
    }
  }

  .second-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .right {
      padding: 10px;

      .title {
        padding: 10px;

        h1 {
          font-family: $unbounded;
          font-weight: 600;
          font-size: 2rem;
        }
      }

      .description {
        .desc {
          font-size: 18px;
          font-weight: 500;
          font-family: $mont;

          span {
            color: $text-color;
            font-family: $unbounded;
            font-size: 1rem;
            font-weight: 700;
            text-decoration: underline;
            text-underline-offset: 10px;
            padding: 0px 10px;
          }
        }
      }
    }
  }

  .accordian-container {
    // width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-left: 1rem;

    .span {
      font-size: 1.2rem;
      font-weight: 600;
      padding: 20px;
      font-family: $unbounded;
    }

    .custom-accordion {
      // border: 1px solid #000;

      .accordian-body {
        font-family: $mont;
        font-weight: 500;
        font-size: 14px;
        border-top: 1px solid #000 !important;
        text-transform: capitalize;
      }

      .accordion-header {
        background-color: inherit !important;
        box-shadow: none !important;
        font-family: $unbounded;

        &:focus,
        &:active {
          background-color: inherit !important;
          box-shadow: none !important;
        }
      }

      .accordion-button {
        background-color: inherit !important;
        box-shadow: none !important;
        font-size: 14px !important;
        font-weight: 400;
        text-transform: capitalizex !important;

        &:focus,
        &:active {
          background-color: inherit !important;
          box-shadow: none !important;
        }

        &:focus {
          color: $text-color;
        }
      }
    }

    .left {
      padding: 10px;
      width: 100%;
      //   display: flex;
      //   align-items: flex-start;
      //   justify-content: space-between;
      //   flex-direction: column;

      .heading {
        font-family: $unbounded;
        font-size: 20px;
        font-weight: 500;
        color: $text-color;
        letter-spacing: 2px;
      }

      .top {
        .title {
          font-family: $unbounded;
          font-size: 16px;
          margin-top: 20px;
          font-weight: 500;
          color: #000;
        }

        .desc {
          font-family: $mont;
          font-weight: 500;
          font-size: 14px;
          border-top: 1px solid #bdbdbd;
          padding: 12px;
        }
      }
    }
  }

  .third-container {
    .top {
      .content {
        // text-align: center;
        padding: 20px;

        .title {
          font-family: $unbounded;
          font-size: 1.8rem;
          font-weight: 600;

          span {
            font-size: 2rem;
            font-weight: 600;
            color: $text-color;
            margin-right: 20px;
          }
        }

        .desc {
          font-family: $mont;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .products-section {
      .title {
        text-align: center;
        padding: 20px;
        font-size: 20px;
        font-family: $mont;
        font-weight: 600;

        span {
          color: $text-color;
          font-size: 26px;
          font-family: $unbounded;
          font-weight: 700;
        }
      }

      .card-gallery {
        width: 100%;
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        gap: 20px;

        .card {
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            background-color: #ebebfa;
            cursor: pointer;
          }

          .image-container {
            width: 300px !important;
            height: 300px !important;

            .image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .card-body {
            .card-title {
              font-family: $unbounded;
              font-weight: 400;
              font-size: 16px;
            }

            .card-text {
              font-family: $mont;
              font-weight: 400;
              font-size: 12px;
            }
          }

          .button {
            padding: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              width: 80% !important;
              text-align: center;
              padding: 10px;
              outline: none;
              border: 1px solid #000;
              background-color: transparent;
              font-family: $mont;
              font-size: 12px;
              font-weight: 500;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
