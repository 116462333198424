@import "../../../../styles/variables.scss";

.identity-container {
  .identity-banner-container {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;

    .identity-banner {
      position: relative;
      width: 100%;
      height: 600px;
      background-image: url("../../../../assests/Producst/Indentity/Facial-Recognisitation.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.736);
        z-index: 1;
      }

      .text-content {
        position: absolute;
        top: 10% !important;
        left: 2%;
        width: 700px;
        color: $headeing-color;
        text-align: left !important;
        z-index: 2;
        width: 500px;

        h1 {
          font-size: rem !important;
          font-weight: 800;
          margin: 0;
          font-family: $unbounded;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          font-family: $mont;
          margin-top: 20px;
        }
      }
    }
  }

  .second-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .right {
      padding: 10px;

      .title {
        padding: 10px 0px 0px 0px;

        h1 {
          font-family: $unbounded;
          font-weight: 500;
          font-size: 2rem;
          color: $text-color;
        }
      }

      .description {
        .desc {
          font-size: 18px;
          font-weight: 500;
          font-family: $mont;
        }
      }
    }
  }

  .content-container {
    .content-1 {
      padding: 1rem;

      .title {
        padding: 10px 0px 10px 0px;

        h1 {
          font-size: 1.6rem;
          font-weight: 500;
          font-family: $unbounded;
          text-transform: capitalize;
          color: $text-color;
        }
      }

      .des {
        font-size: 16px;
        font-weight: 500;
        font-family: $mont;
        text-transform: capitalize;
      }
    }

    .content-2 {
      padding: 1rem;

      .title {
        padding: 10px 0px 10px 0px;

        h1 {
          color: $text-color;
          font-size: 1.6rem;
          font-weight: 500;
          font-family: $unbounded;
        }
      }

      .des {
        font-size: 16px;
        font-weight: 500;
        font-family: $mont;
      }
    }
  }

  .third-container {
    .products-section {
      .title {
        text-align: center;
        padding: 20px;
        font-size: 20px;
        font-family: $mont;
        font-weight: 600;

        span {
          color: $text-color;
          font-size: 26px;
          font-family: $unbounded;
          font-weight: 700;
        }
      }

      .card-gallery {
        .card {
          height: 500px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
          border: 1px solid #000;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            background-color: #ebebfa53;
            cursor: pointer;
          }

          .image-container {
            width: 100%;
            height: 250px !important;

            .image {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .card-body {
            padding: 10px;
            border-top: 1px solid #aeaeae;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .card-title {
              font-family: $unbounded;
              font-weight: 400;
              font-size: 16px;
              margin: 0;
            }

            .card-text {
              font-family: $mont;
              font-weight: 400;
              font-size: 14px;
              margin: 0;
            }
          }

          .button {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .Link {
              text-align: center;
              padding: 10px;
              outline: none;
              border: 1px solid #000;
              background-color: transparent;
              font-family: $mont;
              font-size: 12px;
              font-weight: 500;
              border-radius: 5px;
              text-decoration: none;
              width: 100%; 
              pointer-events: none !important;
            }
          }
        }
      }
    }
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  .identity-container {
    .identity-banner-container {
      position: relative;
      width: 100vw;
      margin: 0 auto;
      overflow: hidden;

      .identity-banner {
        .text-content {
          position: absolute;
          top: 20% !important;
          right: 2%;
          width: 600px;
          color: $headeing-color;
          text-align: right;
          z-index: 2;
          width: 100%;

          h1 {
            font-size: 2rem;
            font-weight: 800;
            margin: 0;
            font-family: $unbounded;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            font-family: $mont;
            margin-top: 20px;
            padding: 10px;
          }
        }
      }
    }
  }
}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  .identity-container {
    .identity-banner-container {
      position: relative;
      width: 100vw;
      margin: 0 auto;
      overflow: hidden;

      .identity-banner {
        .text-content {
          position: absolute;
          top: 15%;
          right: 5%;
          color: $headeing-color;
          text-align: right;
          z-index: 2;
          width: 500px;

          h1 {
            font-size: 3.1rem;
            font-weight: 800;
            margin: 0;
            font-family: $unbounded;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            font-family: $mont;
            margin-top: 20px;
          }
        }
      }
    }

    .second-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .right {
        padding: 10px;

        .title {
          padding: 10px 0px 0px 0px;

          h1 {
            font-family: $unbounded;
            font-weight: 600;
            font-size: 2rem;
            color: $text-color;
          }
        }

        .description {
          .desc {
            font-size: 16px;
            font-weight: 600;
            font-family: $mont;
          }
        }
      }
    }

    .content-container {
      .content-1 {
        padding: 1rem;

        .title {
          padding: 10px 0px 0px 0px;

          h1 {
            font-size: 2rem;
            font-weight: 600;
            font-family: $unbounded;
            text-transform: capitalize;
            color: $text-color;
          }
        }

        .des {
          font-size: 16px;
          font-weight: 600;
          font-family: $mont;
          text-transform: capitalize;
        }
      }

      .content-2 {
        padding: 1rem;

        .title {
          padding: 10px 0px 0px 0px;

          h1 {
            color: $text-color;
            font-size: 2rem;
            font-weight: 500;
            font-family: $unbounded;
          }
        }

        .des {
          font-size: 16px;
          font-weight: 600;
          font-family: $mont;
        }
      }
    }

    .third-container {
      .products-section {
        .title {
          text-align: center;
          padding: 20px;
          font-size: 20px;
          font-family: $mont;
          font-weight: 600;

          span {
            color: $text-color;
            font-size: 26px;
            font-family: $unbounded;
            font-weight: 700;
          }
        }

        .card-gallery {
          .card {
            height: 450px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
            border: 1px solid #000;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              background-color: #ebebfa53;
              cursor: pointer;
            }

            .image-container {
              width: 100%;
              height: 200px;

              .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .card-body {
              padding: 10px;
              border-top: 1px solid #aeaeae;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .card-title {
                font-family: $unbounded;
                font-weight: 400;
                font-size: 16px;
                margin: 0;
              }

              .card-text {
                font-family: $mont;
                font-weight: 400;
                font-size: 14px;
                margin: 0;
              }
            }

            .button {
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;

              .Link {
                text-align: center;
                padding: 10px;
                outline: none;
                border: 1px solid #000;
                background-color: transparent;
                font-family: $mont;
                font-size: 12px;
                font-weight: 500;
                border-radius: 5px;
                text-decoration: none;
                width: 100%; // Full width button
              }
            }
          }
        }
      }
    }
  }
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .identity-container {
    .identity-banner-container {
      position: relative;
      width: 100vw;
      margin: 0 auto;
      overflow: hidden;

      .identity-banner {
        .text-content {
          position: absolute;
          top: 20%;
          right: 5%;
          color: $headeing-color;
          text-align: right;
          z-index: 2;
          width: 500px;

          h1 {
            font-size: 3rem;
            font-weight: 800;
            margin: 0;
            font-family: $unbounded;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            font-family: $mont;
            margin-top: 20px;
          }
        }
      }
    }

    .second-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .right {
        padding: 10px;

        .title {
          padding: 10px 0px 0px 0px;

          h1 {
            font-family: $unbounded;
            font-weight: 600;
            font-size: 2rem;
            color: $text-color;
          }
        }

        .description {
          .desc {
            font-size: 16px;
            font-weight: 600;
            font-family: $mont;
          }
        }
      }
    }

    .content-container {
      .content-1 {
        padding: 1rem;

        .title {
          padding: 10px 0px 0px 0px;

          h1 {
            font-size: 2rem;
            font-weight: 600;
            font-family: $unbounded;
            text-transform: capitalize;
            color: $text-color;
          }
        }

        .des {
          font-size: 16px;
          font-weight: 600;
          font-family: $mont;
          text-transform: capitalize;
        }
      }

      .content-2 {
        padding: 1rem;

        .title {
          padding: 10px 0px 0px 0px;

          h1 {
            color: $text-color;
            font-size: 2rem;
            font-weight: 500;
            font-family: $unbounded;
          }
        }

        .des {
          font-size: 16px;
          font-weight: 600;
          font-family: $mont;
        }
      }
    }

    .third-container {
      .products-section {
        .title {
          text-align: center;
          padding: 20px;
          font-size: 20px;
          font-family: $mont;
          font-weight: 600;

          span {
            color: $text-color;
            font-size: 26px;
            font-family: $unbounded;
            font-weight: 700;
          }
        }

        .card-gallery {
          .card {
            height: 450px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
            border: 1px solid #000;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              background-color: #ebebfa53;
              cursor: pointer;
            }

            .image-container {
              width: 100%;
              height: 150px;

              .image {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .card-body {
              border-top: 1px solid #aeaeae;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .card-title {
                font-family: $unbounded;
                font-weight: 400;
                font-size: 16px;
                margin: 0 !important;
              }

              .card-text {
                font-family: $mont;
                font-weight: 400;
                font-size: 14px;
                margin: 0 !important;
                padding: 0 !important;
              }
            }

            .button {
              display: flex;
              align-items: center;
              justify-content: center;

              .Link {
                text-align: center;
                padding: 10px;
                outline: none;
                border: 1px solid #000;
                background-color: transparent;
                font-family: $mont;
                font-size: 12px;
                font-weight: 500;
                border-radius: 5px;
                text-decoration: none;
                width: 100%; // Full width button
              }
            }
          }
        }
      }
    }
  }
}