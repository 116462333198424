@import "../../../styles/variables.scss";

.blog-container {
  .container {
    .row {
      h1 {
        font-family: $unbounded;
        font-size: 2rem;
        font-weight: 600;
        color: $text-color;
      }
    }

    .blog-grid {
      position: relative;
      box-shadow: 0 1rem 1.75rem rgba(45, 55, 75, 0.1);
      height: 100%;
      border: 0.0625rem solid rgba(220, 224, 229, 0.6);
      border-radius: 0.25rem;
      transition: all 0.2s ease-in-out;

      img {
        width: 100%;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      span {
        color: #292dc2;
      }

      &-text {
        position: relative;

        > span {
          color: #292dc2;
          font-size: 13px;
          padding-right: 5px;
        }

        h3 {
          .Link {
            font-family: $unbounded;
            color: #000;
          }
        }

        p {
          font-family: $mont;
          color: $text-color;
          font-weight: 600 !important;
          &.display-30 {
            font-weight: 400;
            padding: 0;
          }
        }

        .meta {
          &.meta-style2 {
            border-top: 1px dashed #cee1f8 !important;
            padding-top: 15px;

            ul {
              margin: 0;
              padding: 0;

              li {
                margin-bottom: 0;
                font-weight: 500;
                display: inline-block;
                font-size: 14px;
                margin: 5px 10px 5px 0;

                &:last-child {
                  margin-right: 0;
                }

                i {
                  font-size: 14px;
                  font-weight: 600;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }

    .blog-grid-simple-content {
      a {
        color: #575a7b;
        text-decoration: none;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: #1d184a;

          &:after {
            color: #1d184a;
          }
        }
      }
    }

    a,
    a:active,
    a:focus {
      color: #575a7b;
      text-decoration: none;
      transition: color 0.2s ease-in-out;
    }

    .blog-list-left-heading,
    .blog-title-box {
      &:after {
        content: "";
        display: block; // Ensure the pseudo-element is rendered
        height: 2px;
        // You can add more styles like background-color, width, etc., if needed
      }
    }

    .pagination {
      border-radius: 0;
      padding: 0;
      margin: 0;

      ul {
        display: inline-block;
        /* The following two lines are for legacy IE support */
        *display: inline;
        *zoom: 1;
        margin: 0 auto;
        padding: 0;

        li {
          display: inline;

          a {
            float: left;
            padding: 0 18px;
            line-height: 40px;
            text-decoration: none;
            border: 1px solid #dbdbdb;
            border-left-width: 0;
            background: #fff;
            transition: background-color 0.2s ease-in-out,
              color 0.2s ease-in-out;

            &:hover {
              background-color: #1d184a;
              color: #fff;
            }
          }

          &.active {
            a {
              background-color: #f7f7f7;
              color: #999;
              cursor: default;
            }
          }

          &.disabled {
            a,
            span {
              color: #999;
              background-color: transparent;
              cursor: default;

              &:hover {
                color: #999;
                background-color: transparent;
                cursor: default;
              }
            }
          }

          &:first-child a {
            border-left-width: 1px;
          }
        }
      }
    }

    // Margin Utilities
    .mt-6,
    .my-6 {
      margin-top: 3.5rem;
    }
  }
}
