.login-heading {
    font-family: "Technor", sans-serif;
    font-size: 2rem;
    letter-spacing: 2px !important;
    text-decoration: underline;
    text-underline-offset: 8px;
    color: #ec6c4f;
    font-weight: 600;
  }
  
  .login-main-container {
    border: 1px solid #000000;
    width: 600px !important;
    padding: 2rem 0rem;
    border-radius: 25px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .login-lable {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem !important;
    font-weight: 500;
  }
  
  .input {
    font-family: 'Montserrat', sans-serif;
  }
  
  .login-btn {
    width: 60%;
    padding: 6px 6px;
    border: 1px solid #000000;
    border-radius: 10px;
    background-color: transparent !important;
    font-family: 'Montserrat', sans-serif;
  
    &:focus {
      border: 1px solid #ec6c4f;
      color: #000000;
    }
  }
  
  .sub-link {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
  }
  
  .login-links {
    font-family: 'Technor', sans-serif;
    font-size: 1rem !important;
    letter-spacing: 2px;
    text-underline-offset: 8px;
    color: blue;
  }
  
  /* 
  font-family: 'Satoshi', sans-serif;
  font-family: 'Clash Display', sans-serif;
  font-family: 'Switzer', sans-serif;
  font-family: 'Panchang', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Technor', sans-serif;
  */
  