@import "../../../styles/variables.scss";

.contact-main-banner {
  .contact-banner {
    position: relative;
    width: 100%;
    height: 50vh;
    background: url("../../../assests/contact/banner-3.jpg") no-repeat;
    background-size: contain;
    background-position: bottom;
    overflow: hidden;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.791);
    }

    .content {
      position: absolute;
      z-index: 1;
      top: 30%;
      left: 10%;
      width: 500px;

      h1 {
        font-family: $unbounded;
        color: $headeing-color;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.8rem;
      }

      p {
        font-family: $mont;
        color: $headeing-color;
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }

  .product-card {
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
    border: 2px solid $text-color;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &:hover {
      transform: scale(1.05);
    }

    .img-section {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain !important;
        border-radius: 10px;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding: 15px;
      width: 100%;
      overflow: hidden;

      .card-title {
        font-family: "Unbounded", sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: capitalize;
        color: $text-color;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .card-des {
        font-family: $mont;
        font-size: 14px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        padding: 10px !important;
      }
    }
  }

  @media (max-width: 768px) {
    .product-card {
      max-width: 100%;

      .img-section {
        height: 200px;
      }
    }
  }

  @media (max-width: 576px) {
    .product-card {
      max-width: 100%;

      .img-section {
        height: 150px;
      }

      .card-body {
        padding: 10px;

        .card-title {
          font-size: 14px;
        }

        .card-des {
          font-size: 12px;
        }
      }
    }
  }

  .form {
    .input {
      width: 100%;
      padding: 10px;
      border: 1px solid $text-color;

      &::placeholder {
        color: $text-color !important;
        font-family: $mont;
        font-size: 12px;
        font-weight: 500;
      }

      &:focus {
        border: $text-color 2px solid !important;
        box-shadow: none !important;
      }
    }

    .textarea {
      border: 1px solid $text-color;

      &::placeholder {
        color: $text-color;
        font-family: $mont;
        font-size: 12px;
        font-weight: 500;
      }

      &:focus {
        border: $text-color 2px solid !important;
        box-shadow: none !important;
      }
    }

    button {
      cursor: pointer;
      font-weight: 600;
      font-family: $mont;
      transition: all 0.2s;
      padding: 8px 20px;
      background: transparent;
      color: #000000;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      font-size: 12px;

      &:hover {
        background: $main-bg-color;
        color: $text-color;
      }

      & > svg {
        width: 34px;
        margin-left: 10px;
        transition: transform 0.3s ease-in-out;
      }

      &:hover svg {
        transform: translateX(5px);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  .contact-main-banner {
    .contact-banner {
      .content {
        position: absolute;
        z-index: 1;
        top: 10%;
        left: 0%;
        width: 100% !important;

        h1 {
          font-family: $unbounded;
          color: $headeing-color;
          font-size: 2rem;
          font-weight: 500;
          line-height: 3rem;
        }

        p {
          font-family: $mont;
          color: $headeing-color;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }
}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  .contact-main-banner {
    .contact-banner {
      .content {
        position: absolute;
        z-index: 1;
        top: 20%;
        left: 5%;
        width: 600px !important;

        h1 {
          font-family: $unbounded;
          color: $headeing-color;
          font-size: 2.5rem;
          font-weight: 500;
          line-height: 3.5rem;
        }

        p {
          font-family: $mont;
          color: $headeing-color;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .contact-main-banner {
    .contact-banner {
      .content {
        position: absolute;
        z-index: 1;
        top: 20%;
        left: 10%;
        width: 600px;

        h1 {
          font-family: $unbounded;
          color: $headeing-color;
          font-size: 2.5rem;
          font-weight: 500;
          line-height: 3.5rem;
        }

        p {
          font-family: $mont;
          color: $headeing-color;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }

    .product-card {
      margin-bottom: 20px;
      width: 100%;
      max-width: 400px;
      border: 2px solid $text-color;
      border-radius: 10px;
      transition: transform 0.3s ease-in-out;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      &:hover {
        transform: scale(1.05);
      }

      .img-section {
        width: 100%;
        height: 250px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .card-body {
        display: flex;
        flex-direction: column;
        padding: 15px;
        width: 100%;
        overflow: hidden;

        .card-title {
          font-family: "Unbounded", sans-serif;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 2px;
          text-transform: capitalize;
          color: $text-color;
          margin-bottom: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .card-des {
          font-family: $mont;
          font-size: 14px;
          font-weight: 500;
          margin-top: 0;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }
    }

    .form {
      .input {
        width: 100%;
        padding: 10px;
        border: 1px solid $text-color;

        &::placeholder {
          color: $text-color !important;
          font-family: $mont;
          font-size: 12px;
          font-weight: 500;
        }

        &:focus {
          border: $text-color 2px solid !important;
          box-shadow: none !important;
        }
      }

      .textarea {
        border: 1px solid $text-color;

        &::placeholder {
          color: $text-color;
          font-family: $mont;
          font-size: 12px;
          font-weight: 500;
        }

        &:focus {
          border: $text-color 2px solid !important;
          box-shadow: none !important;
        }
      }

      .button {
        cursor: pointer;
        font-weight: 600;
        font-family: $mont;
        transition: all 0.2s;
        padding: 8px 20px;
        background: transparent;
        color: #000000;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        font-size: 12px;

        &:hover {
          background: $main-bg-color;
          color: $text-color;
        }

        & > svg {
          width: 34px;
          margin-left: 10px;
          transition: transform 0.3s ease-in-out;
        }

        &:hover svg {
          transform: translateX(5px);
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}
