@import "../../../styles/variables.scss";

.terms-container{

    .justify-content-center{

        .card-container{

            .mb-4{
                font-family: $unbounded;
                text-transform: capitalize;
                color: $text-color;
            }

            .card-text{
                h5{
                    font-family: $unbounded;
                    font-size: 1.2rem;
                    color: $text-color;
                }

                p{
                    font-family: $mont;
                    text-transform: capitalize;
                    font-weight: 500;
                    padding: 10px;

                    .link{
                        color: $text-color;
                        text-decoration: none;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 700;
                        margin-left: 10px;
                        text-transform: lowercase;

                        &:hover{
                            color: blue;
                            text-decoration: underline;
                        text-underline-offset: 5px;
                        }

                    }

                }

                h4{
                    font-family: $unbounded;
                    color: $text-color;
                }
                h6{
                    font-family: $unbounded;
                    margin: 10px 0px;
                    text-decoration: underline;
                    color: $text-color;
                    text-underline-offset: 5px;
                }
            }

        }

    }


}