@import "../../styles/variables.scss";

.homeContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 40vh;
    gap: 1rem;

    .home-heading {
      font-family: $unbounded;
      font-weight: 600;
      font-size: 2rem;
      text-align: left;
      color: $text-color;
    }

    .home-text {
      font-family: $mont;
      font-weight: 500;
      font-size: 15px;
      text-transform: capitalize;
      text-align: left;
      color: #000 !important;
    }
  }

  .swiper-container {
    video {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}

.card-container {
  .productsHeading {
    font-family: $unbounded;
    color: $text-color;
    font-weight: 600;
  }

  .card {
    width: 100%;
    height: 350px !important;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.671) 0px 5px 15px;
    border: 2px solid $main-bg-color;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .product-img-wrapper {
      width: 100%;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: grayscale(10%);
        transition: filter 0.5s ease;

        &:hover {
          filter: grayscale(0%);
          transform: scale(1.01);
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
    }

    .link {
      color: $text-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 6px;
      }
    }

    .producttitle {
      font-family: $unbounded;
      font-size: 1rem;
      font-weight: 500;
      color: $text-color;
      letter-spacing: 1px;

      &:hover {
        color: #000;
        cursor: pointer;
      }
    }

    .productdesc {
      font-family: $mont;
      font-size: 14px;
      font-weight: 500;
    }
  }


}

.service-container {
  .serviceHeading {
    font-family: $unbounded;
    color: $text-color;
    font-weight: 600;
    line-height: 3rem;
    font-size: 2rem;
    text-align: left;
    padding: 1rem;
    text-transform: capitalize;
  }

  .servicecontent {
    font-family: $mont;
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    text-align: left;
    padding: 1rem;
  }

  .marquee {
    background-color: $text-color;
    color: $headeing-color;
    margin-top: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    font-family: $unbounded;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 2px;
    border-radius: 10px;
    cursor: pointer;
    margin: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    * {
      margin: 0 !important;
    }
  }
}





// Small screens (mobile)
@media (max-width: 600px) {
  .homeContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      .home-heading {
        font-family: $unbounded;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        color: $text-color;
      }

      .home-text {
        font-family: $mont;
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        text-align: left;
        color: #000 !important;
        margin-bottom: 1rem !important;
      }
    }

    .swiper-container {
      video {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }

  .service-container {
    .serviceHeading {
      font-family: $unbounded;
      color: $text-color;
      font-weight: 600;
      font-size: 2rem;
      text-align: left;
      padding: 1rem;
      text-transform: capitalize;
    }

    .servicecontent {
      font-family: $mont;
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      text-align: left;
      padding: 1rem;
    }

    .marquee {
      background-color: $text-color;
      color: $headeing-color;
      margin-top: 2rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      font-family: $unbounded;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2px;
      border-radius: 10px;
      cursor: pointer;
      margin: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      * {
        margin: 0 !important;
      }
    }
  }

}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  .homeContainer {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      .home-heading {
        font-family: $unbounded;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        color: $text-color;
      }

      .home-text {
        font-family: $mont;
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        text-align: left;
        color: #000 !important;
        margin-top: 0px !important;
      }
    }

    .swiper-container {
      video {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }

  .service-container {
    .serviceHeading {
      font-family: $unbounded;
      color: $text-color;
      font-weight: 600;
      font-size: 2.5rem;
      text-align: left;
      padding: 1rem;
      text-transform: capitalize;
    }

    .servicecontent {
      font-family: $mont;
      font-weight: 500;
      font-size: 18px;
      text-transform: capitalize;
      text-align: left;
      padding: 1rem;
    }

    .marquee {
      background-color: $text-color;
      color: $headeing-color;
      margin-top: 2rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      font-family: $unbounded;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2px;
      border-radius: 10px;
      cursor: pointer;
      margin: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      * {
        margin: 0 !important;
      }
    }
  }
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .homeContainer {

    .content {
      .home-heading {
        font-family: $unbounded;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        color: $text-color;
      }

      .home-text {
        font-family: $mont;
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        text-align: left;
        color: #000 !important;
        margin-top: 0px !important;
      }
    }

    .swiper-container {
      video {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }

  .service-container {
    .serviceHeading {
      font-family: $unbounded;
      color: $text-color;
      font-weight: 600;
      font-size: 2.5rem;
      text-align: left;
      padding: 1rem;
      text-transform: capitalize;
    }

    .servicecontent {
      font-family: $mont;
      font-weight: 500;
      font-size: 18px;
      text-transform: capitalize;
      text-align: left;
      padding: 1rem;
    }

    .marquee {
      background-color: $text-color;
      color: $headeing-color;
      margin-top: 2rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      font-family: $unbounded;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2px;
      border-radius: 10px;
      cursor: pointer;
      margin: 1rem;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      * {
        margin: 0 !important;
      }
    }
  }
}