@import "../../styles/variables.scss";

.mainContainer {
  width: 100% !important;
  background-color: $text-color;
  padding: 0.5rem 1rem;

  .brand {
    font-size: 18px;
    font-weight: 500;
    font-family: $unbounded;
    color: $headeing-color;
    filter: saturate(5);
    display: flex;
    align-items: center;
    padding: 0;

    .logo {
      width: 50px;
      height: auto;
      object-fit: cover;
      margin-right: 0.5rem;
    }
  }

  .nav-link {
    font-size: 14px;
    font-weight: 500;
    font-family: $mont;
    color: $headeing-color;
    padding: 0.5rem 0.75rem;

    &:focus {
      color: $headeing-color;
    }
  }

  .dropdown-menu {
    margin-top: 0.6rem;
    background-color: $dropdown1-color;
    padding: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none !important;

    .dropdown-item {
      font-size: 14px;
      font-weight: 500;
      font-family: $mont;
      color: $headeing-color;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid lighten($headeing-color, 20%);

      &:hover {
        background-color: $headeing-color;
        color: #000;
        border-left: 3px solid $headeing-color;
      }

      &:focus {
        color: $headeing-color;
      }
    }

    .dropdown-menu {
      margin-top: 1rem;
      margin-left: 0.6rem;
      background-color: $dropdown2-color;

      .dropdown-item {
        font-size: 12px;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid lighten($headeing-color, 20%);

        &:hover {
          background-color: $headeing-color;
          color: #000;
          border-left: 3px solid $headeing-color;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;
    background-color: transparent;
    color: $headeing-color !important;

    &:active,
    &:focus {
      border: 1px solid #fff !important;
      outline: none !important;
    }

  }

  /* Media query for screens below 1125px */
  @media (max-width: 1125px) {
    .dropdown-menu {
      padding: 0.3rem;
      margin-top: 0.2rem;
      box-shadow: none;
    }

    .dropdown-item {
      padding: 0.3rem 0.75rem;
      font-size: 12px;
    }

    .brand {
      font-size: 16px;
    }

    .nav-link {
      font-size: 12px;
      padding: 0.3rem 0.5rem;
    }

    /* You can adjust dropdown's position here if needed */
    .dropdown-menu {
      top: 100%;
      /* Adjust dropdown position */
      left: 0;
      /* Change based on your needs */
      right: auto;
    }
  }
}