@import "../../styles/variables.scss";

// Footer Styles
.footer {
  background: linear-gradient(180deg, #101010 50%, black 100%) !important;
  color: #fff;

  .footer-top {
    padding: 1rem 0;

    @media (min-width: 768px) {
      padding: 2rem 0;
    }

    @media (min-width: 992px) {
      padding: 3rem 0;
    }

    .container {
      .row {
        .col-lg-4,
        .col-lg-3,
        .col-lg-2 {
          margin-bottom: 2rem;

          @media (min-width: 992px) {
            margin-bottom: 4rem;
          }
        }
      }
    }

    .footer-border-bottom {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;

      .text-lg-end {
        text-align: end;
      }

      h4 {
        font-size: 1.5rem;
        font-family: $unbounded;
      }

      span {
        font-family: $mont;
      }

      .row {
        .text-white {
          p {
            font-family: $mont;
          }
        }
      }

      .input-group {
        .form-control {
          border-radius: 0;
          border: 0;
        }

        .btn-secondary {
          border-radius: 0;
          font-family: $unbounded;
        }
      }
    }
  }

  .footer-social-icon {
    display: flex;
    align-items: center;

    a {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #f84e77;
      color: #ffffff;
      border-radius: 50%;
      margin-right: 8px;
      font-size: 15px;

      &:hover {
        background: #1baaa0;
      }
    }
  }

  .footer-links {
    margin: 0;

    li + li {
      padding-top: 10px;

      .link {
        position: relative;
        text-decoration: none !important; // Ensures no underlines
        color: rgb(103, 163, 11) !important; 

        &:after {
          content: "";
          position: absolute;
          width: 0;
          left: 0;
          bottom: 0;
          height: 1px;
          transition: ease all 0.35s;
          background: #eee; 
          font-family: $mont;
          margin-top: 15px !important;
        }

        &:hover:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #101010;
    padding: 0.5rem 0;

    .container {
      text-align: center;

      p {
        margin: 0;

        a {
          color: rgba(255, 255, 255, 0.65);
          text-decoration: none;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  .avatar-lg {
    img {
      width: 90px;
      height: 90px;
    }
  }
}

h5 {
  font-family: $unbounded;
}

p {
  font-family: $mont;
}

h6 {
  .Link {
    font-family: $mont;
    text-decoration: none !important;
    color: $text-color !important;
  }
}
