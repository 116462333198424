@import "../../../styles/variables.scss";

.single-product {
  .aside {
    .image-container {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      .images {
        width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 0.5rem;
        object-fit: contain;
      }
    }

    .item-thumb {
      margin: 1rem 0.8rem;
      padding: 5px;
      display: inline-block;
      border: 1px solid #dee2e6;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: border-color 0.3s, box-shadow 0.3s;

      &:hover {
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }

      .thumbnail {
        width: 60px;
        height: 60px;
        border-radius: 0.25rem;
        object-fit: cover;
      }
    }

    .d-flex {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
  }

  .main {
    .title {
      color: $text-color !important;
      font-family: $unbounded;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .product-name {
      font-family: $mont;
      font-size: 1rem;
      font-weight: 600;
      color: #343a40;
      margin-bottom: 1rem;
    }

    .description {
      font-family: $mont;
      margin-bottom: 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
    }

    .key-features {
      margin-bottom: 1.5rem;

      h1 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 1rem;
        font-family: $unbounded;
      }

      .features {
        list-style-type: disc;
        margin-left: 1.5rem;
        font-family: $mont;

        li {
          margin-bottom: 0.75rem;

          strong {
            font-weight: 500;
          }
        }
      }
    }

    .short-heighlight {
      p.des {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-family: $mont;
      }

      .link {
        color: $text-color;
        text-decoration: none;
        font-weight: 500;
        font-family: $mont;
        border: 1px solid #000;
        padding: 8px;

        &:hover {
          background-color: #eee;
          color: #000;
        }
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid #dee2e6;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
