// color codes 

$main-bg-color: #F8EDED;
$text-color: #1A4D2E;
$footer-color: #4F6F52;
$headeing-color: #c6c6fa;
$navbar-color: #84c5af;
$dropdown1-color: #114232;
$dropdown2-color: #12372A;




// Fonts
// _variables.scss
$satoshi: "Satoshi", sans-serif;
$clash-display: "Clash Display", sans-serif;
$switzer: "Switzer", sans-serif;
$panch: "Panchang", sans-serif;
$mont: "Montserrat", sans-serif;
$tech: "Technor", sans-serif;
$unbounded: "Unbounded", sans-serif;
$tech: 'Technor', sans-serif;

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  line-height: 1.5em;
  word-break: break-word;
}

// Small screens (mobile)
@media (max-width: 600px) {
    // Styles for small screens (mobile)
  }
  
  // Medium screens (tablet)
  @media (min-width: 601px) and (max-width: 1024px) {
    // Styles for medium screens (tablet)
  }
  
  // Large screens (desktop)
  @media (min-width: 1025px) {
    // Styles for large screens (desktop)
  }
  
