@import "../../../styles/variables.scss";

.company-container {
  .banner {
    position: relative;
    background-image: url("https://cdn.pixabay.com/photo/2020/01/29/20/24/building-4803602_960_720.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.808);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .banner-content {
      z-index: 2;

      .banner-title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
        font-family: $unbounded;
        text-align: start;
        color: $headeing-color;
      }

      .banner-description {
        font-size: 14px !important;
        font-family: $mont;
        font-weight: 500 !important;
        text-align: start;
        text-transform: capitalize;
      }
    }
  }

  .about-company {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 2rem;

    .title {
      font-family: $unbounded;
      font-size: 2.5rem;
      font-weight: 600;
      color: $text-color;
    }

    .description {
      font-family: $mont;
      font-weight: 600;
      font-size: 16px;
      color: #000;
      text-transform: capitalize;
    }

    .leftside-content,
    .rightside-content {
      flex: 1;
      padding: 10px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .leftside-content {
      .title {
        font-family: $unbounded;
        font-size: 1.5rem;
        font-weight: 700;
        color: $text-color;
      }

      .description {
        .content {
          font-family: $mont;
          font-weight: 500;
          font-size: 16px;
          width: 100%;
        }
      }
    }

    .rightside-content {
      .productimages {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 10px;
        align-items: center;
        justify-content: center;

        .image-container {
          position: relative;
          width: 100% !important;
          height: auto;
          padding: 10px;
          border-radius: 5px;
          background-color: $main-bg-color;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            cursor: pointer;
            transition: filter 0.3s ease;
          }

          .overlay-text {
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;
            font-size: 0.875rem;
            background-color: rgba(0, 0, 0, 0.737);
            padding: 5px 10px;
            border-radius: 5px;
            pointer-events: none;
            font-family: $mont;
          }
        }
      }
    }
  }

  .company-profile-container {
    .text-container {
      .profile-heading {
        font-family: $unbounded;
        font-size: 2rem !important;
        line-height: 3rem;
        color: $text-color;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .profile-paragraph {
        font-family: $mont;
        font-size: 16px !important;
        font-weight: 500;
        text-transform: capitalize !important;
      }
    }
  }

  .swiper-main-container {
    padding: 20px;

    .swiper-heading {
      font-family: $unbounded;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
      text-transform: capitalize;
      color: $text-color;
      text-align: center;
    }

    .swiper-card {
      border-radius: 10px;
      padding: 10px;
      border: 2px solid $text-color;
      margin-bottom: 1rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
      height: 400px !important;

      .image-container {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .overlay {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: start;

        .text {
          margin-top: 10px;
          font-family: $mont;
          font-size: 15px;
          font-weight: 700;
          color: $text-color;
          text-transform: capitalize;
        }

        .description {
          font-family: $mont;
          font-weight: 500;
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }

    @media (max-width: 576px) {
      .swiper-card {
        width: 100%;
        height: auto;
      }
    }
  }

  .partners-container {
    .title {
      text-align: start !important;

      h2 {
        font-size: 2rem;
        font-weight: 600;
        font-family: $unbounded;
        text-transform: capitalize;
        color: $text-color;
        line-height: 3rem;
        margin-bottom: 0.5rem;
      }

      .desc {
        font-family: $mont;
        text-align: start !important;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        margin: 0 auto;
        max-width: 800px;
      }
    }

    .marquee-Container {
      padding: 1rem;
      overflow: hidden;

      .marquee {
        display: flex;
        align-items: center;
        gap: 2rem;

        .image-box {
          flex-shrink: 0;
          width: 400px;
          height: 200px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .images {
            filter: drop-shadow(10px 10px 10px rgba(255, 255, 255, 0.7));
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin: 0;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .title h2 {
        font-size: 1.5rem;
      }

      .desc {
        font-size: 16px;
        max-width: 90%;
      }

      .marquee-Container {
        .marquee {
          .image-box {
            width: 300px;
            height: 150px;
          }
        }
      }
    }

    .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      cursor: pointer;
    }

    .popup-content {
      background: white;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      cursor: auto;

      img {
        max-width: 100%;
        max-height: 80vh;
      }
    }
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  .company-container {
    .partners-container {
      .title {
        text-align: start !important;

        h2 {
          font-size: 2.2rem !important;
          font-weight: 700;
          font-family: $unbounded;
          text-transform: capitalize;
          color: $text-color;
        }

        .desc {
          font-family: $mont;
          font-size: 18px;
          font-weight: 600;
          text-transform: capitalize;
          margin: 0 auto;
          max-width: 800px;
        }
      }

      .marquee-Container {
        padding: 1rem;
        overflow: hidden;

        .marquee {
          display: flex;
          align-items: center;
          gap: 2rem;

          .image-box {
            flex-shrink: 0;
            width: 400px;
            height: 200px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .images {
              filter: drop-shadow(10px 10px 10px rgba(255, 255, 255, 0.7));
              width: 100%;
              height: 100%;
              object-fit: contain;
              margin: 0;
            }
          }
        }
      }

      @media (max-width: 768px) {
        .title h2 {
          font-size: 1.5rem;
        }

        .desc {
          font-size: 16px;
          max-width: 90%;
        }

        .marquee-Container {
          .marquee {
            .image-box {
              width: 300px;
              height: 150px;
            }
          }
        }
      }

      .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        cursor: pointer;
      }

      .popup-content {
        background: white;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        cursor: auto;

        img {
          max-width: 100%;
          max-height: 80vh;
        }
      }
    }
  }
}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  .company-container {
    .partners-container {
      .title {
        text-align: start;

        h2 {
          font-size: 2rem;
          font-weight: 600;
          font-family: $unbounded;
          text-transform: capitalize;
          color: $text-color;
          margin-bottom: 0.5rem;
        }

        .desc {
          font-family: $mont;
          font-size: 18px;
          font-weight: 500;
          text-transform: capitalize;
          margin: 0 auto;
          max-width: 800px;
        }
      }

      .marquee-Container {
        padding: 1rem;
        overflow: hidden;

        .marquee {
          display: flex;
          align-items: center;
          gap: 2rem;

          .image-box {
            flex-shrink: 0;
            width: 400px;
            height: 200px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .images {
              filter: drop-shadow(10px 10px 10px rgba(255, 255, 255, 0.7));
              width: 100%;
              height: 100%;
              object-fit: contain;
              margin: 0;
            }
          }
        }
      }

      @media (max-width: 768px) {
        .title h2 {
          font-size: 1.5rem;
        }

        .desc {
          font-size: 16px;
          max-width: 90%;
        }

        .marquee-Container {
          .marquee {
            .image-box {
              width: 300px;
              height: 150px;
            }
          }
        }
      }

      .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        cursor: pointer;
      }

      .popup-content {
        background: white;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        cursor: auto;

        img {
          max-width: 100%;
          max-height: 80vh;
        }
      }
    }
  }
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .company-container {
    .banner {
      .banner-content {
        z-index: 2;
        .banner-title {
          font-size: 2.8rem;
          font-weight: 500;
          margin-bottom: 1rem;
          font-family: $unbounded;
          text-align: start;
          color: $headeing-color;
        }

        .banner-description {
          font-size: 18px !important;
          font-family: $mont;
          font-weight: 500 !important;
          text-align: start;
          text-transform: capitalize;
        }
      }
    }

    .about-company {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center !important;
      justify-content: space-between !important;
      gap: 2rem;

      .title {
        font-family: $unbounded;
        font-size: 2.5rem;
        font-weight: 600;
        color: $text-color;
      }

      .description {
        font-family: $mont;
        font-weight: 600;
        font-size: 16px;
        color: #000;
        text-transform: capitalize;
      }

      .leftside-content,
      .rightside-content {
        flex: 1;
        padding: 10px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .leftside-content {
        .title {
          font-family: $unbounded;
          font-size: 1.5rem;
          font-weight: 700;
          color: $text-color;
        }

        .description {
          .content {
            font-family: $mont;
            font-weight: 500;
            font-size: 16px;
            width: 100%;
          }
        }
      }

      .rightside-content {
        .productimages {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: 10px;
          align-items: center;
          justify-content: center;

          .image-container {
            position: relative;
            width: 100% !important;
            height: auto;
            padding: 10px;
            border-radius: 5px;
            background-color: $main-bg-color;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            img {
              width: 100%;
              height: auto;
              object-fit: cover;
              cursor: pointer;
              transition: filter 0.3s ease;
            }

            .overlay-text {
              position: absolute;
              bottom: 10px;
              left: 10px;
              color: white;
              font-size: 0.875rem;
              background-color: rgba(0, 0, 0, 0.737);
              padding: 5px 10px;
              border-radius: 5px;
              pointer-events: none;
              font-family: $mont;
            }
          }
        }
      }
    }

    .company-profile-container {
      .text-container {
        .profile-heading {
          font-family: $unbounded;
          font-size: 2rem !important;
          line-height: 3rem;
          color: $text-color;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .profile-paragraph {
          font-family: $mont;
          font-size: 16px !important;
          font-weight: 500;
          text-transform: capitalize !important;
        }
      }
    }

    .partners-container {
      .title {
        text-align: start !important;

        h2 {
          font-size: 2.2rem !important;
          font-weight: 700;
          font-family: $unbounded;
          text-transform: capitalize;
          color: $text-color;
        }

        .desc {
          font-family: $mont;
          text-align: start !important;
          font-size: 18px;
          font-weight: 600;
          text-transform: capitalize;
          margin: 0 auto;
          max-width: 800px;
        }
      }

      .marquee-Container {
        padding: 1rem;
        overflow: hidden;

        .marquee {
          display: flex;
          align-items: center;
          gap: 2rem;

          .image-box {
            flex-shrink: 0;
            width: 400px;
            height: 200px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .images {
              filter: drop-shadow(10px 10px 10px rgba(255, 255, 255, 0.7));
              width: 100%;
              height: 100%;
              object-fit: contain;
              margin: 0;
            }
          }
        }
      }

      @media (max-width: 768px) {
        .title h2 {
          font-size: 1.5rem;
        }

        .desc {
          font-size: 16px;
          max-width: 90%;
        }

        .marquee-Container {
          .marquee {
            .image-box {
              width: 300px;
              height: 150px;
            }
          }
        }
      }

      .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        cursor: pointer;
      }

      .popup-content {
        background: white;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        cursor: auto;

        img {
          max-width: 100%;
          max-height: 80vh;
        }
      }
    }
    rtners-container {
      .title {
        h2 {
          font-size: 2.5rem;
          font-weight: 800;
          font-family: $unbounded;
          text-transform: capitalize;
          padding: 10px;
          color: $text-color;
          line-height: 3rem;
        }

        .desc {
          font-family: $mont;
          font-size: 18px;
          font-weight: 600;
          text-transform: capitalize;
          padding: 10px;
        }
      }

      .marque-Container {
        padding: 1rem;

        .marquee {
          .image-box {
            width: 400px;
            height: 400px;
            overflow: hidden;
            gap: 2rem;
          }

          .images {
            filter: drop-shadow(10px 10px 10px rgb(255, 255, 255));
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin: 10px;
          }
        }
      }

      .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        /* Make sure the popup is on top */
      }

      .popup-content {
        background: white;
        padding: 20px;
        border-radius: 10px;
        position: relative;
      }

      .popup-content img {
        max-width: 100%;
        max-height: 80vh;
        /* Adjust height as needed */
      }

      .popup-overlay {
        cursor: pointer;
        /* Show pointer cursor when hovering over overlay */
      }

      .popup-content {
        cursor: auto;
        /* Default cursor when hovering over the content */
      }
    }
  }
}
