@import "./styles/variables.scss";

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.footer-links li a {
  color: $text-color !important; // Ensure link color is green
  font-family: $mont;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer;
  text-underline-offset: 6px !important;
  text-decoration: none !important; // Ensure no text decoration

}


