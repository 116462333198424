@import "../../../styles/variables.scss";

.drivers-container {
  .text-container {
    h1 {
      font-family: $unbounded;
      color: $text-color;
    }

    h4 {
      font-family: $mont;
    }
  }

  .container {
    max-width: 1200px;
    padding: 20px !important;

    h1 {
      font-family: $unbounded;
      font-size: 20px;
      color: $text-color;
    }

    .card {
      border: 1px solid #000000;
      border-radius: 0.25rem;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

      .card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-title {
          font-family: $unbounded;
          font-size: 15px;
          font-weight: 500;
          color: $text-color;
        }
      }

      button {
        display: flex;
        align-items: center;
        font-size: 0.875rem;

        .fa-download {
          font-size: 1rem;
        }
      }
    }
  }
}

// Small screens (mobile)
@media (max-width: 600px) {
  // Styles for small screens (mobile)
}

// Medium screens (tablet)
@media (min-width: 601px) and (max-width: 1024px) {
  // Styles for medium screens (tablet)
}

// Large screens (desktop)
@media (min-width: 1025px) {
  .drivers-container {
    .text-container {
      h1 {
        text-align: center !important;
        padding: 10px;
        font-family: $unbounded;
        color: $text-color;
      }
    }
  
    .container {
      max-width: 1200px;
      padding: 20px !important;
  
      h1 {
        font-family: $unbounded;
        font-size: 30px;
        color: $text-color;
      }
  
      .card {
        border: 1px solid #000000;
        border-radius: 0.25rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  
        .card-body {
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          .card-title {
            font-family: $unbounded;
            font-size: 15px;
            font-weight: 500;
            color: $text-color;
          }
        }
  
        .button {
          display: flex;
          align-items: center;
          font-family: $unbounded;
          font-size: 0.875rem;
  
          .fa-download {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
