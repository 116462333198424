#notfound {
    position: relative;
    height: 100vh;
  
    .notfound {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 767px;
      width: 100%;
      line-height: 1.4;
      text-align: center;
      padding: 15px;
  
      .notfound-404 {
        position: relative;
        height: 220px;
  
        h1 {
          font-family: 'Panchang', sans-serif;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 186px;
          font-weight: 800;
          margin: 0;
          background: linear-gradient(130deg, #ffa34f, #1A5319);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
          text-transform: uppercase;
        }
      }
  
      h2 {
        font-family: 'Technor', sans-serif;
        font-size: 33px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 25px;
        letter-spacing: 3px;
      }
  
      p {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 25px;
      }
  
      a {
        font-family: 'Montserrat', sans-serif;
        color: #1A5319;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 2px;
        margin-left: 1rem;
  
        &:hover {
          color: #000000;
        }
      }
  
      .notfound-social {
        a {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          width: 40px;
          font-size: 14px;
          color: #ff6f68;
          border: 1px solid #000;
          border-radius: 50%;
          margin: 3px;
          transition: 0.2s all;
  
          &:hover {
            color: #fff;
            background-color: #1a531981;
            border-color: #1A5319;
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 168px;
  
      h1 {
        font-size: 100px;
      }
    }
  
    .notfound h2 {
      font-size: 22px;
    }
  }
  
  /* Font Families */
  /*
  font-family: 'Satoshi', sans-serif;
  font-family: 'Clash Display', sans-serif;
  font-family: 'Switzer', sans-serif;
  font-family: 'Panchang', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Technor', sans-serif;
  */
  